<template>
	<v-card width="100%" height="100%" outlined>
		<table class="table-wr subtitle-1">
			<tr style="background:#F8F8FF">
				<th
					v-for="(header, index) in headers"
					:key="'h' + index"
					class="pa-3 font-weight-bold"
					align="start"
				>
					{{ $t(header) }}
				</th>
			</tr>
			<template v-if="items.length">
				<tr v-for="(item, rowIndex) in items" :key="'r' + rowIndex">
					<td
						v-for="(column_data, colIndex) in item"
						:key="'c' + colIndex"
						class="pa-2"
						:style="styleClass"
					>
						{{ colIndex === 0 ? `${rowIndex + 1}.` : column_data }}
					</td>

					<td v-if="actions && actions.length">
						<v-btn icon v-for="(action, index) in actions" :key="'a' + index">
							<v-icon
								:color="action.color"
								@click="$emit(action.event_name, item[0])"
								>{{ $vuetify.icons.values[action.icon] }}</v-icon
							>
						</v-btn>
					</td>
				</tr>
			</template>
			<template v-else>
				<tr v-if="msg">
					<td :colspan="headers.length" align="center">
						{{ $t(msg) }}
					</td>
				</tr>
			</template>
		</table>

		<v-btn
			v-if="operation"
			large
			fab
			color="success"
			class="add-record-wr"
			@click="$emit(operation.event)"
			:style="styleObj"
		>
			<v-icon large>$vuetify.icons.values.plus</v-icon>
		</v-btn>
	</v-card>
</template>

<script>
export default {
	name: "AppTable",

	props: {
		headers: {
			required: true,
		},
		items: {
			required: true,
		},
		actions: {
			default: null,
		},
		operation: {
			default: null,
		},
		msg: {
			default: null,
		},
	},

	computed: {
		styleClass() {
			return {
				width: `calc(100%/${this.headers.length})`,
			};
		},

		styleObj() {
			return {
				position: "fixed",
				bottom: "150px !important",
				[this.$vuetify.rtl ? "left" : "right"]: "60px",
			};
		},
	},
};
</script>

<style scoped lang="scss">
.table-wr {
	border-collapse: collapse;
	width: 100%;
	td,
	th,
	tr {
		border: 1px solid var(--v-greyLighten2-base);
		padding: 10px 15px 10px 15px;
	}
	th {
		background: #ebebed;
	}
	td {
		font-size: 1.1rem;
	}
}
</style>
